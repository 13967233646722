export const TOKEN_KEY = "user";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () =>
  JSON.parse(localStorage.getItem(TOKEN_KEY))?.token || null;
export const getUser = () =>
  JSON.parse(localStorage.getItem(TOKEN_KEY)) || null;
export const setLocalUser = (user) =>
  localStorage.setItem(TOKEN_KEY, JSON.stringify(user));
export const removeLocalUser = () => localStorage.removeItem(TOKEN_KEY);

