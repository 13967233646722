import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import { useForm } from "react-hook-form";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
// import { Snackbar } from "@material-ui/core";
// import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import api from "../../../../Services/api";
import Footer from "../../../../components/Footer/Footer";
import newBaseApi from "../../../../Services/newBaseApi";

export function SignIn() {
  // const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm();
  const { login } = useContext(AuthContext);
  const history = useHistory();
  const [error, setError] = useState(undefined);

  const onSubmit = async (data) => {
    try {
      setError(undefined);
      const response = await newBaseApi.post("/consumidor/login", data);

      if (response.data && response.data.token) {
        login(response.data);
        // history.push("/profile");
      } else {
        console.error("API:", response.data);
        setError(response.data.message);
        // setOpen(true);
      }
    } catch (error) {
      setError(error.response?.data.message || error.message);
      console.error("Erro no login:", error.response?.data || error.message);
    }
  };

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          marginTop: "-55px",
        }}
      >
        <div className="row mt-20">
          <div className="col-md-12 text-center">
            <h1 className="mt-30 login__title">Faça login para continuar</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 offset-md-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group text-center login-block">
                <label htmlFor="usuario">Email</label>

                <div className="user">
                  <FontAwesomeIcon className="user-icon" icon={faUser} />
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    {...register("email", {
                      required: "O email é obrigatório",
                    })}
                  />
                </div>
              </div>

              <div className="form-group text-center login-block">
                <label htmlFor="senha">Senha</label>

                <div className="user">
                  <FontAwesomeIcon className="user-icon" icon={faLock} />
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    {...register("password", {
                      required: "A senha é obrigatória",
                    })}
                  />
                </div>
              </div>

              <div className="form-group text-center login-block">
                <p>
                  Não possui conta?{" "}
                  <a href="/sign-up">
                    Cadastre-se agora!
                  </a>
                </p>
              </div>

              {error && (
                <p
                  className="error-message"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {error}
                </p>
              )}

              <div class="form-group text-center">
                <button className="btn btn-login" disabled={isSubmitting}>
                  {isSubmitting ? "Entrando" : "Entrar"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          E-mail ou senha inválidos!
        </Alert>
      </Snackbar> */}
    </>
  );
}
