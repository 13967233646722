import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import fotoDetault from "../../../Assets/defaultuser.jpeg";
import { PhoneMask } from "../../CriarMeuEvento/utils/masks";

import "./css/index.css";

import newBaseApi from "../../../Services/newBaseApi";

export default function PerfilConsumidor() {
  const [perfil, setPerfil] = useState({
    consumidor_nome: "",
    consumidor_celular: "",
    consumidor_telefone: "",
    consumidor_sexo: "",
    consumidor_data_nascimento: "",
    consumidor_foto: "",
    email: "",
    consumidor_cidade_id: "",
    consumidor_estado_id: "",
  });
  const [fotoOriginal, setFotoOriginal] = useState(null);

  const [senha, setSenha] = useState({
    password: "",
    confirm_password: "",
  });

  const [editando, setEditando] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    carregarPerfil();
  }, []);

  async function carregarPerfil() {
    try {
      setLoading(true); // Ativa o estado de carregamento
      const response = await newBaseApi.get("/consumidor/user");
      setPerfil({
        consumidor_nome: response.data.consumidor_nome || "",
        consumidor_celular: response.data.consumidor_celular || "",
        consumidor_telefone: response.data.consumidor_telefone || "",
        consumidor_sexo: response.data.consumidor_sexo || "",
        consumidor_data_nascimento:
          response.data.consumidor_data_nascimento || "",
        consumidor_foto: response.data.consumidor_foto || fotoDetault,
        email: response.data.email || "",
      });
      setFotoOriginal(response.data.consumidor_foto || fotoDetault);
    } catch (error) {
      toast.error("Erro ao carregar perfil do consumidor.");
    } finally {
      setLoading(false); // Desativa o estado de carregamento
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const maskedValue =
      name === "consumidor_celular" || name === "consumidor_telefone"
        ? PhoneMask(value)
        : value;

    setPerfil((prevPerfil) => ({ ...prevPerfil, [name]: maskedValue }));
  };

  const handleSenhaChange = (e) => {
    const { name, value } = e.target;
    setSenha((prevSenha) => ({ ...prevSenha, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPerfil((prevPerfil) => ({
          ...prevPerfil,
          consumidor_foto: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const salvarAlteracoes = async () => {
    const dataISO = perfil.consumidor_data_nascimento
      ? perfil.consumidor_data_nascimento
      : null;

    const payload = {
      consumidor_nome: perfil.consumidor_nome,
      email: perfil.email,
      consumidor_celular: perfil.consumidor_celular,
      consumidor_telefone: perfil.consumidor_telefone || null,
      consumidor_sexo: perfil.consumidor_sexo || null,
      consumidor_data_nascimento: dataISO,
      consumidor_foto:
        perfil.consumidor_foto === fotoOriginal ? null : perfil.consumidor_foto,
    };

    try {
      setSaving(true); // Ativa o estado de carregamento
      await newBaseApi.put("/consumidor/alterarPerfil", payload);
      toast.success("Perfil atualizado com sucesso!");
      setEditando(false);
      carregarPerfil();
    } catch (error) {
      toast.error("Erro ao salvar as alterações.");
    } finally {
      setSaving(false); // Desativa o estado de carregamento
    }
  };

  const salvarSenha = async () => {
    if (senha.password !== senha.confirm_password) {
      toast.error("As senhas não coincidem.");
      return;
    }

    try {
      await newBaseApi.put("/consumidor/alterarPerfil", {
        email: perfil.email,
        password: senha.password,
        confirm_password: senha.confirm_password,
        consumidor_nome: perfil.consumidor_nome,
      });
      toast.success("Senha atualizada com sucesso!");
      setSenha({ password: "", confirm_password: "" });
    } catch (error) {
      toast.error("Erro ao atualizar a senha.");
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Carregando perfil...</p>
      </div>
    );
  }

  return (
    <div className="perfil-container">
      <h1 className="perfil-titulo">Meu Perfil</h1>
      <div className="perfil-content">
        <div className="perfil-left">
          <img
            src={perfil.consumidor_foto || fotoDetault}
            alt="Perfil"
            className="perfil-foto"
          />
          {editando && (
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="foto-input"
              disabled={!editando || saving}
            />
          )}
        </div>
        <div className="perfil-center">
          <div className="perfil-campo">
            <label>Email:</label>
            <p className="campo-desabilitado">{perfil.email || "Não informado"}</p>
          </div>
  
          {[
            {
              label: "Nome",
              name: "consumidor_nome",
              value: perfil.consumidor_nome,
            },
            {
              label: "Celular",
              name: "consumidor_celular",
              value: PhoneMask(perfil.consumidor_celular),
            },
            {
              label: "Telefone",
              name: "consumidor_telefone",
              value: PhoneMask(perfil.consumidor_telefone),
            },
            {
              label: "Sexo",
              name: "consumidor_sexo",
              value: perfil.consumidor_sexo,
              type: "select",
            },
            {
              label: "Data de Nascimento",
              name: "consumidor_data_nascimento",
              value: perfil.consumidor_data_nascimento
                ? editando
                  ? perfil.consumidor_data_nascimento
                  : format(
                      new Date(perfil.consumidor_data_nascimento + "T00:00:00"),
                      "dd/MM/yyyy"
                    )
                : "",
              type: editando ? "date" : "text",
            },
          ].map((campo) => (
            <div className="perfil-campo" key={campo.name}>
              <label>{campo.label}:</label>
              {editando ? (
                campo.type === "select" ? (
                  <select
                    name={campo.name}
                    value={campo.value}
                    onChange={handleInputChange}
                    className="input-style" // Adicionando classe de estilo
                  >
                    <option value="">Selecione...</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                    <option value="Outro">Não Binário</option>
                    <option value="Não informar">Não quero informar</option>
                  </select>
                ) : (
                  <input
                    type={campo.type || "text"}
                    name={campo.name}
                    value={campo.value}
                    onChange={handleInputChange}
                    disabled={!editando || saving}
                  />
                )
              ) : (
                <p className="campo-desabilitado">{campo.value || "Não informado"}</p> // Adicionando a classe correta aqui
              )}

            </div>
          ))}
  
          <div className="perfil-acoes">
            {editando ? (
              <>
                <button
                  onClick={salvarAlteracoes}
                  className="btn-salvar"
                  disabled={saving}
                >
                  {saving ? "Salvando..." : "Salvar"}
                </button>
                <button
                  onClick={() => setEditando(false)}
                  className="btn-cancelar"
                  disabled={!editando || saving}
                >
                  Cancelar
                </button>
              </>
            ) : (
              <button onClick={() => setEditando(true)} className="btn-editar">
                Editar Perfil
              </button>
            )}
          </div>
        </div>
        <div className="perfil-right">
          <h2>Alterar Senha</h2>
          <div className="perfil-campo">
            <label>Nova Senha:</label>
            <input
              type="password"
              name="password"
              value={senha.password}
              onChange={handleSenhaChange}
              className="input-style"
            />
          </div>
          <div className="perfil-campo">
            <label>Confirmar Nova Senha:</label>
            <input
              type="password"
              name="confirm_password"
              value={senha.confirm_password}
              onChange={handleSenhaChange}
              className="input-style"
            />
          </div>
          <div className="perfil-acoes">
            <button
              onClick={salvarSenha}
              className="btn-salvar"
              disabled={saving}
            >
              Alterar Senha
            </button>
            <button
              onClick={() => setSenha({ password: "", confirm_password: "" })}
              className="btn-cancelar"
              disabled={saving}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  
  
}
