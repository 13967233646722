import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import React from "react";
import Avaliacoes from "./components/Avaliacoes";
import Informacoes from "./components/Informacoes";
import Promocoes from "./components/Promocoes";
import Slide from "./components/slide";
import "./css/tabs.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div className="box-tables">{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonAuto(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root.tabs}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Informações" {...a11yProps(0)} />
          <Tab label="Foto/Video" {...a11yProps(1)} />
          {/* 
          {promoValue.length >= 1 ? (
            <Tab label="Promoções" {...a11yProps(2)} />
          ) : (
            ""
          )}

          {rateValue.length >= 1 ? (
            <Tab label="Avaliações" {...a11yProps(3)} />
          ) : (
            ""
          )} */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div>
          <Informacoes data={props.data} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Slide imagens={props.data.galeria} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Promocoes />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Avaliacoes />
      </TabPanel>
    </div>
  );
}

